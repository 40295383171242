import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';

const Loading = React.memo(function Loading({data = null, size = ""}){

    const style_error = {
        color: '#dc3545',
    };

    const fa_class = (size === "") ? "fa-2x" : "";

    return(
        <Row>
            <Col className="d-flex justify-content-center mt-2 mb-2">
                { (ApiLoading(data) === null) ? <Spinner color="primary" size={size} /> : <i className={`fas fa-times-circle ${fa_class}`} style={style_error} /> }
            </Col>
        </Row>
    );
});

function ApiLoading(status){
    if(status === null) return null;
    return undefined;
};

export default Loading;