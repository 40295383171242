/* Reducery pro sektor management */
import { MANAGEMENT_USERS, USER_PERMISSIONS, ARCHITECTS_EDIT } from '../Types';
import update from 'immutability-helper';

const def_state = {
   //seznam vsech dostupnych opravneni
   permissions: [],
   //data pro editaci konkretniho uzivatele
   architects_edit: [],
};

export default function management(state = def_state, action){
   switch(action.type){
      /*case MANAGEMENT_USERS:
         return action.payload;*/
      case USER_PERMISSIONS:
         return update(state, {permissions: {$set: action.payload}});
      //data pro editaci editovaneho architekta
      case ARCHITECTS_EDIT:
         return update(state, {architects_edit: {$set: action.payload}});
      default:
         return state;
   }
};