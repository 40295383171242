import { MANAGEMENT_FORMS, USER_FULL, USER_USERS, USER_LOGGEDIN } from '../Types';
import Axios from '../../_axios';

/* asynchronni fce, ktere obstaravaji dotazy do api a maji minimalni logiku */

/**
 * Stáhne nám informace o daném (aktuálně přihlášeném) uživatelovi
 */
export const getUserInfoLogin = () => {
   return(dispatch) => {
      Axios.get('/users/actual').then(response => {
         dispatch(fetchUser(response.data[0]));
      }).catch(err => {
         //odpoved prizpusobime tvaru v jakym chodi z API a v jakym ukldame (ulozime undefined do permissions i k userovi)
         dispatch(fetchUser({ undefined, perm: undefined}));
      });
   }
}

//aktualizuje seznam "architektu", ktere spadaji pod daneho uzivatele
export const updateUsers = () => {
   return(dispatch) => {
      return Axios.get("/users").then(response => {
         dispatch(fetchUsers(response.data));
      }).catch(err => {
         //musime upravit format tak, aby sedel s formatem, ve kterym ukladame user informace pokud prijdou z APIcka v poradku
         dispatch(fetchUser({ undefined, perm: { undefined }}));
      });
   };
};

export const setLoggedIn = logged_in => {
   return(dispatch) => dispatch({type: USER_LOGGEDIN, payload: logged_in});
};

/**
 * aktualizuje aktulni nastaveni uzivatele podle klice hodnoty
 * @param {bool} to_api - pokud je true, tak danou informaci posle i do API, jinak neposila
 */ 
export const updateUserSettings = (key, value, to_api = true) => {
   return(dispatch) => {

   }
};

//seznam uzivatelovych "architektu"
const fetchUsers = data => {
   return{
      type: USER_USERS,
      payload: {
         architects: data,
      }
   }
};

const fetchUser = data => {
   const { perm, ...user_info } = data;
   return{
      type: USER_FULL,
      payload: {
         perm: perm,
         user: user_info,
      }
   }
};