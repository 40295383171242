import { TRANSLATE_LOAD, TRANSLATE_LOGIN } from '../Types';
import Axios from '../../_axios';

export const getTranslates = () => {
    return(dispatch) => {
        Axios.get("/translates").then(res => {
            dispatch(setTranslates(res.data));
        }).catch(err => {
            dispatch(setTranslates(undefined));
        });
    }
}

export const getTranslatesLogin = () => {
    return(dispatch) => {
        Axios.get("/translates/login", {headers: {locales: navigator.languages || 'en-US'}}).then(res => {
            dispatch(setTranslatesLogin(res.data.login_page));
        }).catch(err => {
            dispatch(setTranslatesLogin(undefined));
        })
    }
};

const setTranslates = translates => (
    {
        type: TRANSLATE_LOAD,
        payload: translates
    }
);

const setTranslatesLogin = translates => (
    {
        type: TRANSLATE_LOGIN,
        payload: translates
    }
)
