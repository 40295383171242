import { 
   APPS_APPS,
} from '../../Types';
import update from 'immutability-helper';
import { ApiFetching } from 'dvmp-components';

const default_state = {
   //pole vsech aplikaci ke stazeni
   apps: ApiFetching,
};

export default function apps(state = default_state, action){
   switch(action.type){
      case APPS_APPS:
         return update(state, {apps: {$set: action.payload}});
      default:
         return state;
   }
};