import { FETCH_USER, LOGIN_STATUS, LOGOUT_USER, USER_FULL, LOGIN_REDIRECT, LOGIN_TRANSLATE } from '../Types';
import axios from '../../_axios';
import Cookie from 'js-cookie';
import { getUserInfoLogin } from './User';
import { getTranslates } from './Translate';
import { getRoutes } from './Router';
/* asynchronni fce, ktere obstaravaji dotazy do api a maji minimalni logiku */

/**
 * Funkce, která nám načte uživatele a další potřebné věci pro zalogování uživatele.
 * Tuto funkci voláme jakmile máme v Cookies platný API klíč a slouží pro finální přihlášení uživatele.
 * Na login page pak už jenom hlídáme, jestli máme stáhnuté potřebné věci a jakmile máme splněny všechny podmínky stáhnutí, tak redirectujeme uživatele do webu.
 */
export const loadUser = () => {
   return(dispatch) => {
      dispatch(getUserInfoLogin());
      dispatch(getTranslates());
      dispatch(getRoutes());
   };
};

/**
 * Pokusí se získat API klíč z loginu uživatele, pokud API klíč získáme, tak je uživatel oprávněný pro vstup do aplikace a pošleme požadavek na načtení usera.
 * Funkci loginUser voláme ze stránky loginu pouze v případě, že user nemá v cookies platný API klíč a chce se přihlásit do aplikace.
 * @param {string} username - login uzivatele
 * @param {string} password - heslo uzivatele
 * @param {bool} no_logout - jestli budeme automaticky odhlasovat uzivatele, true = neodhlasujeme
 */
export const loginUser = (username, password, no_logout) => {
   return(dispatch) => {
      dispatch(setLoginPending());
      axios.get("/user/login", {headers: {login: username, password: password}}).then(response => {
         //TODO ulozit do .env
         //const domain = (process.env.NODE_ENV === 'production') ? "private.hanak-nabytek.cz" : "hanak-nabytek.cz";
         try{
            const expires = (no_logout === true) ? 2000 : Number.parseInt(process.env.REACT_APP_LOGOUT_TIME);
            Cookie.set(process.env.REACT_APP_COOKIE_API_KEY_NAME, response.data.api_key, { expires: expires, domain: 'hanak-nabytek.cz' });
            //login proběhl úspěšně, máme API klíč, takže stáhneme potřebné data pro přihlášení uživatele do aplikace
            dispatch(loadUser());
         } catch(e){
            dispatch(setLoginError('Přihlášení ze nezdařilo'));
         }
      }).catch(error => {
         dispatch(setLoginError(error.response.data || ""));
      });
   }
};

//pouzijeme v pripade, kdy jsme presmerovani na login stranku z jine stranky, protoze nejsme prihlaseni
export const redirectUrl = url => {
   return(dispatch) => {
      dispatch(setRedirectUrl(url));
   }
};

/* synchronni fce, ktere se volaji na zaklade logiky vyse */

/*export const fetchUserSuccess = data => {
   console.log(data);
   return{
      type: FETCH_USER,
      payload: {
         logged_in: true,
         id: data.id_user,
         real_name: data.real_name,
         surname: data.surname,
         company_name: data.company_name,
         phonenumber: data.phonenumber,
         email: data.email,
         perms: data.perm,
         api_token: data.api_token
      }
   }
};*/

/*export const logoutUser = () => {
   Cookie.remove('api-token');
   return {
      type: LOGIN_STATUS,
      payload: {
         loginPending: false,
         loginError: false,
         loginSuccess: false
      }
   }
}*/

export const setLoginPending = () => {
   return {
      type: LOGIN_STATUS,
      payload: {
         loginPending: true,
         loginError: false,
         loginSuccess: false
      }
   }
};

export const setLoginError = error => {
   return {
      type: LOGIN_STATUS,
      payload: {
         loginPending: false,
         loginError: error,
         loginSuccess: false
      }
   }
};

export const setLoginSuccess = () => {
   return {
      type: LOGIN_STATUS,
      payload: {
         loginPending: false,
         loginError: false,
         loginSuccess: true
      }
   }
};

const setRedirectUrl = url => {
   return{
      type: LOGIN_REDIRECT,
      payload: {
         url: url
      }
   };
};

export const logoutUser = () => {
   return{
      type: LOGOUT_USER,
      payload: {}
   }
}

//kompletni data daneho uzivatele
export const fetchUser = data => {
   return{
      type: USER_FULL,
      payload: {
         //TODO - [] muzeme tahat z pole
         user: data.user,
         permissions: data.user.perm,
         settings: {
            stay_logged: data.no_logout
         },
      }
   }
};