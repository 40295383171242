import { PERSISTER_NOLOGOUT } from '../Types';
import update from 'immutability-helper';

const def_state = {
   no_logout: false, //uzivatel nechce byt automaticky odhlasovan
};

/**
 * Slouzi pro ukladani veci, ktere maji prezit obnoveni stranky
 */
export default function persister(state = def_state, action){
   switch(action.type){
      case PERSISTER_NOLOGOUT:
         return update(state, {no_logout: {$set: action.payload}});
      default:
         return state;
   }
}