import { ROUTER_GETROUTES, ROUTER_GETROUTESFAIL } from '../Types';
import Axios from '../../_axios';

export const getRoutes = () => {
   return(dispatch) => {
      Axios.get("/routes").then(res => {
         dispatch(setRoutes(res.data));
      }).catch(err => {
         dispatch(setRoutesFail());
      });
   };
};

const setRoutesFail = () => (
   {
      type: ROUTER_GETROUTESFAIL,
   }
);

const setRoutes = routes => (
   {
      type: ROUTER_GETROUTES,
      payload: routes
   }
);