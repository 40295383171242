import { combineReducers } from 'redux';

import management from './Management';
import user from './User';
import login from './Login';
import informator from './Informator';
import offers from './Offers';
import translate from './Translate';
import router from './Router';
import apps from './Pages/Apps';
import persister from './Persister';
import components from './Components';

const reducer = combineReducers({
   login: login,
   translate: translate,
   user: user,
   components: components,
   management: management,
   informator: informator,
   offers: offers,
   router: router,
   apps: apps,
   persister: persister,
});

export default reducer;
