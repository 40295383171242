import { KATALOG_LIST, KATALOG_PDF, } from '../Types/Components/Katalogs';
import { EXPEDITION_WEEK } from '../Types/Components/Expedition';
import { INFORMATOR_FILTERBAR } from '../Types/Components/Informator';
import { processApiRoutine } from 'redux-thunk-routine-helpers/dist/reducers';
import { default_api } from 'redux-thunk-routine-helpers/dist/defaults';

const default_state = {
   [KATALOG_LIST]: default_api,
   [KATALOG_PDF]: default_api,
   [EXPEDITION_WEEK]: default_api,
   [INFORMATOR_FILTERBAR]: default_api,
};

export default function components(state = default_state, action){
   const process_api_routine = processApiRoutine(state, action);
   if(process_api_routine !== false) return process_api_routine;

   return state;
};