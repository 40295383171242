export const FETCH_USER = 'FETCH_USER';
export const USER_FULL = 'USER_FULL';
export const USER_USERS = 'USER_USERS';
export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

/** login types **/
export const LOGIN_STATUS = 'LOGIN_STATUS';
//data pro ulozeni adresy, kam budeme po loginu redirectovat uzivatele
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';
export const LOGIN_TRANSLATE = 'LOGIN_TRANSLATE';

/** preklady **/
export const TRANSLATE_LOAD = 'TRANSLATE_LOAD';
export const TRANSLATE_LOGIN = 'TRANSLATE_LOGIN';

/** sprava architektu **/
export const MANAGEMENT_USERS = 'MANAGEMENT_USERS';
export const MANAGEMENT_FORMS = 'MANAGEMENT_FORMS';

/** formulare **/
export const USER_PERMISSIONS = 'USER_PERMISSIONS';
export const ARCHITECTS_EDIT = 'ARCHITECTS_EDIT';

/** informator **/
export const INFORMATOR_GETMESSAGES = 'INFORMATOR_GETMESSAGES';
export const INFORMATOR_GETMESSAGE = 'INFORMATOR_GETMESSAGE';

/** offers **/
//pole jednotlivych nabidek
export const OFFERS = 'OFFERS';
//popisek co znamena ktera barva v tabulce nabidek
export const OFFERS_LEGEND = 'OFFERS_LEGEND';
//detail jedne nabidky
export const OFFERS_DETAIL = 'OFFERS_DETAIL';
export const OFFERS_DETAIL_ITEMS = 'OFFERS_DETAIL_ITEMS';
//stahne nam formular pro editaci parametru
export const OFFERS_PARAMETERS_FORM = 'OFFERS_PARAMETERS_FORM';
//seznam typu nabidky
export const OFFERS_TYPES = 'OFFERS_TYPES';
//priznak, jestli jsou vyplnene parametry a zakazka je pripravena k odeslani
export const OFFERS_PARAMETERS_FILLED = 'OFFERS_PARAMETERS_FILLED';
//vysledek pri posilani parametru nabidky
export const OFFERS_PARAMETERS_STATUS = 'OFFERS_PARAMETERS_STATUS';
//hodnoty formulare, ktere vyplni uzivatel
export const OFFERS_FORM_VALUES = 'OFFERS_FORM_VALUES';
export const OFFERS_ATTACHMENTS = 'OFFERS_ATTACHMENTS';
export const OFFERS_PREVIEW_IMAGE = 'OFFERS_PREVIEW_IMAGE';

export const ROUTER_GETROUTES = 'ROUTER_GETROUTES';
export const ROUTER_GETROUTESFAIL = 'ROUTER_GETROUTESFAIL';

export const APPS_APPS = 'APPS_APPS';
export const PROFI_KATALOG_PDF_FETCH = 'PROFI_KATALOG_PDF_FETCH';
export const PROFI_KATALOG_LIST_FETCH = 'PROFI_KATALOG_LIST_FETCH';
export const PROFI_KATALOG_LIST_DATA = 'PROFI_KATALOG_LIST_DATA';
export const PROFI_KATALOG_PDF_DATA = 'PROFI_KATALOG_PDF_DATA';

export const PERSISTER_NOLOGOUT = 'PERSISTER_NOLOGOUT';