/* Reducer, ve kterym se starama o uzivatelova data - id, jmeno, prijmeni, ... */
import { 
    OFFERS, 
    OFFERS_LEGEND, 
    OFFERS_DETAIL, 
    OFFERS_DETAIL_ITEMS, 
    OFFERS_PARAMETERS_FORM, 
    OFFERS_TYPES, 
    OFFERS_PARAMETERS_FILLED, 
    OFFERS_PARAMETERS_STATUS, 
    OFFERS_FORM_VALUES,
    OFFERS_ATTACHMENTS,
    OFFERS_PREVIEW_IMAGE,
} from '../Types';
import update from 'immutability-helper';

const def_state = {
    offers: null,
    offers_legend: null,
    offers_detail: null,
    //jednotlive polozky v danne nabidce
    offers_detail_items: null,
    parameters: {
        types: [],
        form: {},
        //vysledek odeslani parametru nabidky
        post_status: {
            status: null,
            message: null,
        },
        //hodnoty, ktere zada uzivatel
        user_input: {
            form: null,
            files: null,
        }
    },
    attachments: null,
    preview_image: null,
}

export default function offers(state = def_state, action){
   switch(action.type){
      /*case LOGIN_STATUS:
         return action.payload;*/
        case OFFERS:
            return update(state, {offers: {$set: action.payload}});
        case OFFERS_LEGEND:
            return update(state, {offers_legend: {$set: action.payload}});
        case OFFERS_DETAIL:
            return update(state, {offers_detail: {$set: action.payload}});
        case OFFERS_DETAIL_ITEMS:
            return update(state, {offers_detail_items: {$set: action.payload}});
        case OFFERS_PARAMETERS_FORM:
            return update(state, {parameters: {form: {$set: action.payload}}});
        case OFFERS_TYPES:
            return update(state, {parameters: {types: {$set: action.payload}}});
        case OFFERS_PARAMETERS_FILLED:
            return update(state, {parameters: {filled: {$set: action.payload}}});
        case OFFERS_PARAMETERS_STATUS:
            return update(state, {parameters: {post_status: {$set: action.payload}}});
        case OFFERS_FORM_VALUES:
            return update(state, {parameters: {user_input: {form: {$set: action.payload}}}});
        case OFFERS_ATTACHMENTS:
            return update(state, {attachments: {$set: action.payload}});
        case OFFERS_PREVIEW_IMAGE:
            return update(state, {preview_image: {$set: action.payload}});
        default:
            return state;
   }
};