/* REDUX */
import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
//importuje rootReducer z index.js - export default combineReducers (se pak tvari prave jako ten rootReducer...)
import rootReducer from './Redux/Reducers';
import logger from 'redux-logger';

import { persistReducer, createMigrate } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import createEncryptor from 'redux-persist-transform-encrypt';


//sifrovator pro persistor storage
//nejedna se o extra zabezpeceni, jako o schovani primych dat pred zvedavci (pro security bychom museli vyresit secretKey, aby nesel najit)
const encryptor = createEncryptor({
   secretKey: 'sd54Gbřžd&cerfWewrě',
   onError: error => {
      console.error("Encryptor failed!");
   }
});

const migrations = {
   //verze 1 je migrace, kdy persistor pouzivame opravdu na nejnutnejsi veci a tak zahodime veskere puvodni data co maji uzivatele ulozene
   1: state => undefined
}

let persistConfig = {
   key: 'main',
   version: 1,
   migrate: createMigrate(migrations, { debug: false }),
   storage,
   whitelist: [
      'persister',
      //'user',
      /*'login',
      'offers',
      'informator',
      'router',
      'apps',
      'management',*/
   ]
};

//pokud jsme v produkcni verzi, tak pridame encryptor
if(process.env.NODE_ENV === 'production'){
   persistConfig = { ...persistConfig, transforms: [ encryptor ] };
   console.log = () => {};
}

let middleware = [ ReduxThunk ];
if(process.env.NODE_ENV !== 'production'){
   middleware = [ ...middleware, logger ];
}

//nastavime persistentni overflow nad reducerem
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, {}, compose(applyMiddleware(...middleware)));

export default store;