/* Reducer, ve kterym se starama o uzivatelova data - id, jmeno, prijmeni, ... */
import { USER_FULL, USER_USERS, USER_LOGGEDIN } from '../Types';
import update from 'immutability-helper';

const def_state = {
   logged_in: false, //defaultne neni uzivatel prihlasenej
   architects: [],
   permissions: null, //na null overujeme na login page, musi takhle zustat
   settings: { //uzivatelske nastaveni
   },
   user: null //na null overujeme na login page, musi takhle zustat
};

export default function user(state = def_state, action){
   switch(action.type){
      case USER_FULL:
         return update(state, {permissions: {$set: action.payload.perm}, user: {$set: action.payload.user}});
      case USER_USERS: //vracime jenom architekty, kteri spadaji pod daneho uzivatele
         return update(state, {architects: {$set: action.payload}});
      case USER_LOGGEDIN:
         return update(state, {logged_in: {$set: action.payload}});
      default:
         return state;
   }
};
