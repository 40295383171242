/* Reducer, ve kterym se starama o uzivatelova data - id, jmeno, prijmeni, ... */
import { LOGIN_STATUS, LOGIN_REDIRECT, LOGIN_TRANSLATE } from '../Types';
import update from 'immutability-helper';

const def_state = {
   state: {
      loginError: null,
      loginPending: null,
      loginSuccess: null,
   },
   redirect: null,
};

export default function login(state = def_state, action){
   switch(action.type){
      case LOGIN_STATUS:
         return update(state, {state: {$set: action.payload}});
      case LOGIN_REDIRECT:
         return update(state, {redirect: {$set: action.payload}});
      case LOGIN_TRANSLATE:
         return update(state, {translate: {$set: action.payload}});
      default:
         return state;
   }
};