/* Reducer, ve kterym ukladame veci k informatoru, ... */
import { TRANSLATE_LOAD, TRANSLATE_LOGIN } from '../Types';
import update from 'immutability-helper';

const default_state = {
   translates: null, //null overujeme na login strance, takze nemenit
   translate_login: null
};

export default function forms(state = default_state, action){
   switch(action.type){
      case TRANSLATE_LOAD: //veskere preklady
         return update(state, {translates: {$set: action.payload}});
      case TRANSLATE_LOGIN: //preklady pro login stranku
         return update(state, {translate_login: {$set: action.payload}});
      default:
         return state;
   }
};