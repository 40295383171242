import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from './component/ProtectedRoute';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

//import console from 'dev-console.macro';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store from './store';

import Loading from './helpers/Loading';

const loading = () => <Loading/>;

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  render() {
      const persistor = persistStore(store);
      return (
         <BrowserRouter>
            <Provider store={store}>
               <PersistGate loading={loading()} persistor={persistor}>
                  <React.Suspense fallback={loading()}>
                     <Switch>
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                        {/*<Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />*/}
                        <ProtectedRoute path="/" name="Home" />
                        <Redirect to="/Apps" />
                     </Switch>
                  </React.Suspense>
               </PersistGate>
            </Provider>
         </BrowserRouter>
      );
  }
}

export default App;
