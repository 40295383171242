import axios from 'axios';
import Cookie from 'js-cookie';
//import store from './store';

import { api_url } from './_api_url';

const timeout = 60000;
//const api_url = "https://api.hanak-nabytek.cz";
//const api_token = store.getState().user.user.api_token;

const Axios = axios.create({
      baseURL: api_url,
      //baseURL: 'http://192.168.17.210:8000',
      //baseURL: 'http://server.hanak-nabytek.cz:8000',
      timeout: timeout,
      //headers: { 'api-token': api_token }
});

Axios.interceptors.request.use(
   function(config){
      const token = Cookie.get(process.env.REACT_APP_COOKIE_API_KEY_NAME);
      //if(!!token) config.headers.Authorization = 'Bearer ' + token;
      if(!!token) config.headers['api-token'] = token;
      return config;
   },
   function(error){
      return Promise.reject(error);
   }
);

/* Pripojeni vice objektu pres jeden connect */
/*
const mapStateToProps = state => {
   return {
      api_token: state.user.api_token
   };
};

const ContainerConnector = connect(mapStateToProps);

const Ax = ContainerConnector(_Axios);*/
//const Ax_noheaders = ContainerConnector(_Axios_noheaders);

export default Axios;
/*export default Axios_noheaders;*/