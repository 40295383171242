import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AutomaticLogout } from 'dvmp-components';
import Cookie from 'js-cookie';

/*Date.prototype.addHours = function(h){
   this.setTime(this.getTime() + (h * 3600000));
   return this;
};*/

Date.prototype.addMinutes = function(m){
   this.setTime(this.getTime() + (m * 60000));
   return this;
}

//uzivatel provede nejakou akci, kterou povazujeme za aktivitu uzivatele
const toggleActivity = () => {
   const cookie_name = process.env.REACT_APP_COOKIE_API_KEY_NAME;
   Cookie.set(cookie_name, Cookie.get(cookie_name), { expires: new Date().addMinutes(Number.parseInt(process.env.REACT_APP_LOGOUT_TIME)), domain: 'hanak-nabytek.cz' });
};

export default function MyAutomaticLogout({ signOut }){

   const translate = useSelector(store => store.translate.translates.logout_modal);

   useEffect(() => {
      //pri mountu nastavime Cookie time na vychozi hodnoty
      toggleActivity();
   }, [ ]);

   const auto_logout = {
      logout:{
        time: Number.parseInt(process.env.REACT_APP_LOGOUT_TIME),
        logout: signOut,
      },
      warn: {
        time: Number.parseInt(process.env.REACT_APP_LOGOUT_WARN),
        message: translate.message.value,
        header: translate.header.value,
        no_logout: translate.no_logout.value,
        logout: translate.logout.value,
      }
    };
 
   return <AutomaticLogout logout={auto_logout.logout} warn={auto_logout.warn} onActivity={toggleActivity}/>
}