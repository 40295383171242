import { ROUTER_GETROUTES, ROUTER_GETROUTESFAIL } from '../Types';
import update from 'immutability-helper';

const def_state = {
   //pole rout, ktere ma uzivatel dle sveho opravneni k dispozici
   routes: [],
   //navigace - hlavni menu
   nav: {
      items: []
   },
   user_menu: [],
   //pri stahnuti upravime na true a tak vime, ze mame pozadovane data stazene
   ready: false,
};

export default function router(state = def_state, action){
   switch(action.type){
      case ROUTER_GETROUTES:
         return update(state, {routes: {$set: action.payload.routes}, nav: {$set: action.payload.nav}, user_menu: {$set: action.payload.user_menu}, ready: {$set: true}});
      case ROUTER_GETROUTESFAIL:
         return update(state, { ready: {$set: undefined}});
      default:
         return state;
   }
}