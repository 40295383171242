/* Reducer, ve kterym ukladame veci k informatoru, ... */
import { INFORMATOR_GETMESSAGES, INFORMATOR_GETMESSAGE } from '../Types';
import update from 'immutability-helper';

const default_state = {
   messages: null,
   message: {}
};

export default function forms(state = default_state, action){
   switch(action.type){
      //zpravy informatoru
      case INFORMATOR_GETMESSAGES:
         return update(state, {messages: {$set: action.payload}});
      case INFORMATOR_GETMESSAGE:
         return update(state, {message: {$set: action.payload}});
      default:
         return state;
   }
};