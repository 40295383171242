import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
//import { redirectUrl } from '../Redux/Actions/Login';
import { getRoutes } from '../Redux/Actions/Router';
import AutomaticLogout from './AutomaticLogout';
import { logoutUser } from '../Redux/Actions/Login.js';
import { getTranslates } from '../Redux/Actions/Translate';
import Cookie from 'js-cookie';
import storage from 'redux-persist/lib/storage';
//overime, jestli je uzivatel prihlasenej, jinak automaticky redirect na login

// Containers
const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout'));

export default function ProtectedRoute(props){

   const dispatch = useDispatch();

   //defaulne mame false, po prihlaseni upravujeme na true
   const logged_in = useSelector(state => state.user.logged_in);
   //const api_token = useSelector(state => state.user.user.api_token);
   const stay_logged = useSelector(state => state.persister.no_logout);
   //const translates = useSelector(state => state.translate.translates);

  /* useEffect(() => {
      //pokud budeme redirectovat na login stranku, tak si ulozime puvodni url z kama lezeme
      if(logged_in === true ){
         //overime si, jestli nam sedi api klic axiosu s api klicem uzivatele (pokud se logujeme pod jinym uzivatelem, tak by to mohl byt zasadni problem)
         //const axios = require('../_axios').default;
         //const store = require('../store').default;
         //const api_token = store.getState().user.user.api_token;
         //if(axios.defaults.headers["api-token"] !== api_token) axios.defaults.headers["api-token"] = api_token;
         //console.log("budu stahovat data!");
         //stahneme preklady
         //dispatch(getTranslates());
         //stahneme uzivatelovi routy
         //dispatch(getRoutes());
      }
   }, [ ]);*/

   const signOut = useCallback(() => {
      storage.removeItem('persist:main');
      //nastavi cookie na undefined
      Cookie.remove(process.env.REACT_APP_COOKIE_API_KEY_NAME, { domain: 'hanak-nabytek.cz'});
      window.location.reload();
   });

   //pokud jsme prihlaseni a mame api klic v cookies, tak vratime DefaultLayout, jinak redirectujeme na login page
   return (
      (logged_in === true && typeof Cookie.get(process.env.REACT_APP_COOKIE_API_KEY_NAME) !== 'undefined') ? (
         <React.Fragment>
            {(stay_logged === false) ? <AutomaticLogout signOut={signOut} /> : null}
            <Route path={props.path} name={props.name} render={props => <DefaultLayout signOut={signOut} {...props} />} />
         </React.Fragment>
      ) : (
         <Route render={() => <Redirect to={{pathname: "/login", search: props.location.search, state: { referrer: props.location.pathname }}} /> }/>
      )
   ); 
}